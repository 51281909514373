import nightOwl from "@theme-ui/prism/presets/night-owl.json"
import * as tokens from "../../build/js"
import { gridGlobals } from "./globals/grid"
import { linkGlobals } from "./globals/link"
import { listGlobals } from "./globals/list"
import { sectionHeadingGlobals } from "./globals/section-heading"

const theme = {
  colors: {
    text: tokens.systemColorForegroundDefault,
    textInverse: tokens.systemColorForegroundInverse,
    background: tokens.systemColorBackgroundDefault,
    backgroundInverse: tokens.systemColorBackgroundInverse,
    backgroundHighContrast: tokens.systemColorBackgroundHighContrastDefault,
    backgroundHighContrastInverse:
      tokens.systemColorBackgroundHighContrastInverse,
    primary: tokens.systemColorPrimaryMuted,
    primarySubtle: tokens.systemColorPrimarySubtle,
    // secondary: tokens.systemPrimaryForeground,
    // accent: tokens.systemPrimaryForeground,
    // highlight: tokens.systemPrimaryForeground,
    muted: tokens.systemColorForegroundMutedDefault,
    lowContrast: tokens.systemColorSecondaryLowContrast,
    subtle: tokens.systemColorForegroundSubtleDefault,
    borderSubtle: tokens.systemColorForegroundSubtleInverse,
    foregroundGradient01: tokens.systemColorGradientForeground,
    backgroundGradient01: tokens.systemColorGradientBackground,

    modes: {
      dark: {
        text: tokens.systemColorForegroundInverse,
        textInverse: tokens.systemColorForegroundDefault,
        background: tokens.systemColorBackgroundInverse,
        backgroundInverse: tokens.systemColorBackgroundDefault,
        primary: tokens.systemColorPrimarySubtle,
        // secondary: tokens.systemPrimaryForeground,
        // accent: tokens.systemPrimaryForeground,
        // highlight: tokens.systemPrimaryForeground,
        muted: tokens.systemColorForegroundMutedInverse,
        lowContrast: tokens.systemColorForegroundLowContrastInverse,
        subtle: tokens.systemColorForegroundSubtleInverse,
      },

      black: {
        text: tokens.systemColorForegroundInverse,
        textInverse: tokens.systemColorForegroundDefault,
        background: tokens.systemColorBackgroundHighContrastInverse,
        backgroundInverse: tokens.systemColorBackgroundDefault,
        primary: tokens.systemColorPrimarySubtle,
        muted: tokens.systemColorForegroundMutedInverse,
        lowContrast: tokens.systemColorForegroundLowContrastInverse,
        subtle: tokens.systemColorForegroundSubtleInverse,
      },

      // blue: {
      //   text: tokens.systemColorPrimaryForeground,
      //   background: tokens.systemColorPrimaryBackground,
      //   primary: tokens.systemColorPrimaryForeground,
      //   // secondary: tokens.systemPrimaryForeground,
      //   // accent: tokens.systemPrimaryForeground,
      //   // highlight: tokens.systemPrimaryForeground,
      //   muted: tokens.systemColorPrimaryMuted,
      //   lowContrast: tokens.systemColorPrimaryLowContrast,
      //   subtle: tokens.systemColorPrimarySubtle,
      // },

      // purple: {
      //   text: tokens.systemColorSecondaryForeground,
      //   background: tokens.systemColorSecondaryBackground,
      //   primary: tokens.systemColorSecondaryForeground,
      //   // secondary: tokens.systemPrimaryForeground,
      //   // accent: tokens.systemPrimaryForeground,
      //   // highlight: tokens.systemPrimaryForeground,
      //   muted: tokens.systemColorSecondaryMuted,
      //   lowContrast: tokens.systemColorSecondaryLowContrast,
      //   subtle: tokens.systemColorSecondarySubtle,
      // },

      white: {
        background: tokens.systemColorBackgroundHighContrastDefault,
      },
    },
  },

  space: [
    tokens.globalSpacing0,
    tokens.globalSpacing1,
    tokens.globalSpacing2,
    tokens.globalSpacing3,
    tokens.globalSpacing4,
    tokens.globalSpacing5,
    tokens.globalSpacing6,
    tokens.globalSpacing7,
  ],

  radii: {
    default: [
      "0.25rem",
      "0.25rem",
      tokens.globalRadiusDefault,
      tokens.globalRadiusDefault,
    ],
    large: [
      "0.5rem",
      "0.5rem",
      tokens.globalRadiusLarge,
      tokens.globalRadiusLarge,
    ],
    pill: tokens.globalRadiusPill,
  },

  fonts: {
    body: `"${tokens.globalFontFamilyDefault}", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
    monospace: `"${tokens.globalFontFamilyMono}", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace`,
    condensed: `"${tokens.globalFontFamilyCondensed}", -apple-system, "Arial Narrow", sans-serif`,
    heading: "inherit",
  },

  fontSizes: [
    tokens.globalFontSize0,
    tokens.globalFontSize1,
    tokens.globalFontSize2,
    tokens.globalFontSize3,
    tokens.globalFontSize4,
    tokens.globalFontSize5,
    tokens.globalFontSize6,
    tokens.globalFontSize7,
    tokens.globalFontSize8,
    tokens.globalFontSize9,
    tokens.globalFontSize10,
    tokens.globalFontSize11,
    tokens.globalFontSize12,
    tokens.globalFontSize13,
    tokens.globalFontSize14,
  ],

  fontWeights: {
    body: tokens.globalFontWeightRegular,
    heading: tokens.globalFontWeightMedium, // Not using this but need it here for a valid theme spec.
    light: tokens.globalFontWeightLight,
    normal: tokens.globalFontWeightRegular,
    medium: tokens.globalFontWeightMedium,
    semiBold: tokens.globalFontWeightSemiBold,
    bold: tokens.globalFontWeightBold,
  },

  lineHeights: {
    flat: tokens.globalLineHeight100,
    heading: tokens.globalLineHeight125,
    body: tokens.globalLineHeight150,
  },

  letterSpacings: [
    tokens.globalLetterSpacing0,
    tokens.globalLetterSpacing1,
    tokens.globalLetterSpacing2,
    tokens.globalLetterSpacing3,
    tokens.globalLetterSpacing4,
    tokens.globalLetterSpacing5,
    tokens.globalLetterSpacing6,
    tokens.globalLetterSpacing7,
    tokens.globalLetterSpacing8,
    tokens.globalLetterSpacing9,
  ],

  shadows: {
    shadow01: tokens.systemElevation1,
    shadow02: tokens.systemElevation2,
    shadow03: tokens.systemElevation3,
    shadow04: tokens.systemElevation4,
    shadow05: tokens.systemElevation5,
    shadow06: tokens.systemElevation6,
  },

  breakpoints: [
    tokens.breakpointSm,
    tokens.breakpointMd,
    tokens.breakpointLg,
    tokens.breakpointXl,
  ],

  sizes: {
    sm: tokens.sizeSm,
    md: tokens.sizeMd,
    lg: tokens.sizeLg,
    xl: tokens.sizeXl,
    container: tokens.sizeXl,
  },

  layout: {
    container: {
      maxWidth: "container",
      px: [4, 4, 6, 6],
    },

    mainContentFooter: {
      pt: [5, 5, 6, 6],
    },
  },

  grids: {
    default: {
      ...gridGlobals,
      gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
    },

    cards: {
      ...gridGlobals,
      gridTemplateColumns: "repeat(auto-fill, minmax(30.5rem, 1fr))",
      rowGap: 4,
    },

    servicesBlock: {
      ...gridGlobals,
      gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
      gridGap: 0,
    },
  },

  text: {
    body: {
      fontSize: [4, 4, 5, 5],
    },

    small: {
      fontSize: 4,
    },

    paragraph: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      mt: 0,
      mx: 0,
      mb: [4, 4, 5, 5],
    },

    lead: {
      variant: "text.paragraph",
      fontSize: [5, 6, 7, 7],
    },

    caption: {
      fontSize: [3, 3, 4, 4],
      color: "textMuted",
    },

    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      mt: 0,
    },

    h1: {
      variant: "text.heading",
      fontWeight: "medium",
      fontSize: [9, 9, 10, 10],
      letterSpacing: 0,
      mb: [1, 2, 3, 3],
    },

    h2: {
      variant: "text.heading",
      fontWeight: "medium",
      fontSize: [8, 8, 9, 9],
      letterSpacing: 1,
      mb: [1, 2, 3, 3],
    },

    h3: {
      variant: "text.heading",
      fontWeight: "medium",
      fontSize: [6, 6, 7, 7],
      letterSpacing: 2,
      mb: [1, 1, 2, 2],
    },

    h4: {
      variant: "text.heading",
      fontWeight: "semiBold",
      fontSize: [5, 5, 6, 6],
      letterSpacing: 3,
      mb: [1, 1, 2, 2],
    },

    h5: {
      variant: "text.heading",
      fontWeight: "semiBold",
      fontSize: [4, 4, 5, 5],
      letterSpacing: 4,
      mb: [1, 1, 2, 2],
    },

    h6: {
      variant: "text.heading",
      fontWeight: "semiBold",
      fontSize: 4,
      letterSpacing: 5,
      mb: [1, 1, 2, 2],
    },

    sectionHeading: {
      small: {
        ...sectionHeadingGlobals,
        fontSize: 4,
      },

      large: {
        ...sectionHeadingGlobals,
        fontSize: [4, 4, 5, 5],
      },
    },

    timeStamp: {
      variant: "eyebrow",
    },

    logoType: {
      fontFamily: "condensed",
      fontWeight: "semiBold",
      fontSize: 4,
      lineHeight: "heading",
      letterSpacing: 9,
      textTransform: "uppercase",
    },
  },

  links: {
    default: {
      ...linkGlobals,
    },
  },

  lists: {
    default: {
      variant: "text.paragraph",
      listStyleType: '"- "',
      pl: [2, 2, 3, 3],
      mt: 0,

      li: {
        mb: [2, 2, 4, 4],

        "&:last-child": {
          mb: 0,
        },
      },
    },

    small: {
      variant: "text.small",
      pl: [1, 1, 2, 2],

      li: {
        mb: [1, 1, 2, 2],
      },
    },

    unstyled: {
      ...listGlobals.unstyled,
    },

    smallUnstyled: {
      variant: "lists.small",
      ...listGlobals.unstyled,
    },
  },

  cards: {
    primary: {
      backgroundColor: "backgroundHighContrast",
      borderRadius: "default",
      mb: 4,
    },
  },

  eyebrow: {
    fontFamily: "monospace",
    fontSize: 3,
    color: "muted",
    textTransform: "uppercase",
    letterSpacing: 9,
  },

  footerBlock: {
    width: "100%",
    minHeight: ["auto", "auto", "20rem", "18rem"],
  },

  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontVariantLigatures: "discretionary-ligatures",
      fontFeatureSettings: "dlig",
      color: "text",
      height: "100%",
      webkitFontSmoothing: "antialiased",

      body: {
        variant: "text.body",
        height: "100%",
      },

      a: {
        variant: "links.default",
      },

      // "*:link, *:visited, *:hover, *:active, *:focus": {
      //   transition:
      //     "color .15s linear, background-color .15s linear, background-position .3s ease-out, border-color .15s linear, opacity .15s linear, fill .15s linear",
      // },

      p: {
        variant: "text.paragraph",
      },

      ul: {
        variant: "lists.default",
      },

      dl: {
        my: 0,
      },

      dd: {
        ml: 0,
      },

      paragraph: {
        variant: "text.paragraph",
      },

      h1: {
        variant: "text.h1",
      },

      h2: {
        variant: "text.h2",
      },

      h3: {
        variant: "text.h3",
      },

      h4: {
        variant: "text.h4",
      },

      h5: {
        variant: "text.h5",
      },

      h6: {
        variant: "text.h6",
      },

      small: {
        variant: "text.small",
      },

      table: {
        borderCollapse: "collapse",
        mb: 4,

        thead: {
          th: {
            padding: 2,
            verticalAlign: "bottom",
            borderBottomWidth: 2,
            borderBottomStyle: "solid",
            borderColor: "subtle",
          },
        },

        td: {
          padding: 2,
          verticalAlign: "top",
          borderBottomWidth: 2,
          borderBottomStyle: "solid",
          borderColor: "subtle",
        },
      },

      figure: {
        mt: 0,
        mx: 0,
        mb: 6,
      },
    },

    hr: {
      borderBottom: "1px solid",
      borderColor: "divider",
    },

    blockquote: {
      borderLeft: "4px solid",
      borderColor: "muted",
      fontStyle: "italic",
      pl: 3,
      my: 4,
    },

    code: {
      ...nightOwl,
      borderRadius: "default",
      p: 2,
    },

    svg: {
      display: "inline-block",
      width: "100%",
      height: "auto",
      stroke: "currentColor",
      color: "currentColor",
      fill: "none",
    },
  },
}

export default theme
