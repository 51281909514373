/**
 * Do not edit directly
 * Generated on Fri, 04 Oct 2024 00:09:39 GMT
 */

export const componentColorBlockContactForegroundDefault = "#f5f6f7";
export const componentColorBlockContactForegroundMuted = "#cac6c7";
export const componentColorBlockContactForegroundBorder = "#444549";
export const componentColorBlockContactBackgroundDefault = "#000000";
export const componentColorBlockServicesForeground = "#303133";
export const componentColorBlockServicesBackground = "#ffffff";
export const componentColorBlockServicesBorder = "#cac6c7";
export const componentColorButtonPrimaryForeground = "#f5f6f7";
export const componentColorButtonPrimaryBackground = "#1B1C1D";
export const componentColorButtonSecondaryForeground = "#303133";
export const componentColorButtonSecondaryBackground = "#f5f6f7";
export const componentColorCardBaseBackground = "#ffffff";
export const componentColorCardProjectCompanyName = "#5a595e";
export const componentColorCardProjectTitle = "#303133";
export const componentColorCardProjectProjectDescription = "#5a595e";
export const componentColorCtaLinkPrimaryForeground = "#303133";
export const componentColorCtaLinkSecondaryForeground = "#f5f6f7";
export const componentColorDividerForeground = "#5a595e";
export const componentColorFaviconForeground = "#303133";
export const componentColorFooterForeground = "#cac6c7";
export const componentColorFooterBackground = "#000000";
export const componentColorFooterBorder = "#5a595e";
export const componentColorHeaderDefaultForeground = "#303133";
export const componentColorHeaderDefaultBackground = "#f5f6f7";
export const componentColorHeaderDefaultBorder = "#e0dedd1a";
export const componentColorHeaderInverseForeground = "#f5f6f7";
export const componentColorHeaderInverseBackground = "#1B1C1D";
export const componentColorHeaderInverseBorder = "#4445491a";
export const componentColorHeadingStandardForeground = "#303133";
export const componentColorHeadingSectionForeground = "#303133";
export const componentColorHeroDefaultForeground = "#303133";
export const componentColorHeroDefaultEyebrow = "#5a595e";
export const componentColorHeroDefaultDescription = "#5a595e";
export const componentColorHeroDefaultBackground = "#f5f6f7";
export const componentColorHeroDefaultHighlight = "#b6cbf3";
export const componentColorHeroHomeJobTypeTitleForeground = "linear-gradient(90deg, #2b69e133 0%, #e12b4c33 100%)";
export const componentColorHeroHomeJobTypeTitleBackground = "linear-gradient(90deg, #2b69e133 0%, #e12b4c33 100%)";
export const componentColorIconForeground = "#303133";
export const componentColorImageContentForeground = "#5a595e";
export const componentColorImagePlaceholderForeground = "#5a595e";
export const componentColorImagePlaceholderBackground = "#ffffff";
export const componentColorImagePlaceholderBorder = "#e0dedd";
export const componentColorLogoForeground = "#303133";
export const componentColorTextLeadForeground = "#303133";
export const componentColorTextParagraphForeground = "#303133";
export const componentColorTextSubTextForeground = "#5a595e";
export const systemColorForegroundDefault = "#303133";
export const systemColorForegroundInverse = "#f5f6f7";
export const systemColorForegroundMutedDefault = "#5a595e";
export const systemColorForegroundMutedInverse = "#cac6c7";
export const systemColorForegroundLowContrastDefault = "#cac6c7";
export const systemColorForegroundLowContrastInverse = "#5a595e";
export const systemColorForegroundSubtleDefault = "#e0dedd";
export const systemColorForegroundSubtleInverse = "#444549";
export const systemColorForegroundHighContrastDefault = "#000000";
export const systemColorBackgroundDefault = "#f5f6f7";
export const systemColorBackgroundInverse = "#1B1C1D";
export const systemColorBackgroundHighContrastDefault = "#ffffff";
export const systemColorBackgroundHighContrastInverse = "#000000";
export const systemColorPrimaryBackground = "#ebf2ff";
export const systemColorPrimaryForeground = "#0039af";
export const systemColorPrimaryMuted = "#2b69e1";
export const systemColorPrimaryLowContrast = "#8daeed";
export const systemColorPrimarySubtle = "#b6cbf3";
export const systemColorSecondaryBackground = "#efebff";
export const systemColorSecondaryForeground = "#29098d";
export const systemColorSecondaryMuted = "#5b3cc3";
export const systemColorSecondaryLowContrast = "#ac97f9";
export const systemColorSecondarySubtle = "#cbbefb";
export const systemColorGradientBackground = "linear-gradient(90deg, #2b69e133 0%, #e12b4c33 100%)";
export const systemColorGradientForeground = "linear-gradient(90deg, #2b69e1 0%, #e12b4c 100%)";
export const coreComponentColorButtonLightForeground = "#f5f6f7";
export const coreComponentColorButtonLightBackground = "#1B1C1D";
export const coreComponentColorButtonDarkForeground = "#303133";
export const coreComponentColorButtonDarkBackground = "#f5f6f7";
export const coreComponentColorHeaderLightForeground = "#303133";
export const coreComponentColorHeaderLightBorder = "#3031331a";
export const coreComponentColorHeaderDarkForeground = "#f5f6f7";
export const coreComponentColorHeaderDarkBorder = "#f5f6f71a";
export const coreComponentColorHeroLightForeground = "#303133";
export const coreComponentColorHeroLightEyebrow = "#5a595e";
export const coreComponentColorHeroLightDescription = "#5a595e";
export const coreComponentColorHeroDarkForeground = "#f5f6f7";
export const coreComponentColorHeroDarkEyebrow = "#cac6c7";
export const coreComponentColorHeroDarkDescription = "#cac6c7";
export const coreComponentTextButtonLabel = "400 18px/100% Plus Jakarta Sans";
export const coreComponentTextCtaLinkLabel = "600 22px/150% Plus Jakarta Sans";
export const coreComponentTextHeaderLogoType = "600 18px/125% Barlow Condensed";
export const coreComponentTextHeroHomeTitle = "700 96px/125% Plus Jakarta Sans";
export const coreComponentOpacityHeroHomeJobTypeBackground = "0.2";
export const breakpointSm = "768px";
export const breakpointMd = "1044px";
export const breakpointLg = "1236px";
export const breakpointXl = "1464px";
export const sizeSm = "768px";
export const sizeMd = "1044px";
export const sizeLg = "1236px";
export const sizeXl = "1464px";
export const systemTextHeadingH1 = "500 60px/125% Plus Jakarta Sans";
export const systemTextHeadingH2 = "500 40px/125% Plus Jakarta Sans";
export const systemTextHeadingH3 = "500 32px/125% Plus Jakarta Sans";
export const systemTextHeadingH4 = "600 28px/125% Plus Jakarta Sans";
export const systemTextHeadingH5 = "600 22px/125% Plus Jakarta Sans";
export const systemTextHeadingH6 = "600 18px/125% Plus Jakarta Sans";
export const systemTextHeadingAltLarge = "600 22px/125% Barlow Condensed";
export const systemTextHeadingAltSmall = "600 18px/125% Barlow Condensed";
export const systemTextBodyBody = "400 22px/150% Plus Jakarta Sans";
export const systemTextBodyLead = "400 32px/150% Plus Jakarta Sans";
export const systemTextBodyLarge = "400 36px/150% Plus Jakarta Sans";
export const systemTextBodySmall = "400 18px/150% Plus Jakarta Sans";
export const systemTextBodyXSmall = "400 14px/150% Plus Jakarta Sans";
export const systemTextBodyEyebrow = "400 14px/150% Space Mono";
export const systemTextBodyTimeStamp = "400 14px/150% Space Mono";
export const systemTextBodyCaption = "400 18px/150% Plus Jakarta Sans";
export const coreLightColorForegroundDefault = "#303133";
export const coreLightColorForegroundInverse = "#f5f6f7";
export const coreLightColorForegroundMutedDefault = "#5a595e";
export const coreLightColorForegroundLowContrastDefault = "#cac6c7";
export const coreLightColorForegroundSubtleDefault = "#e0dedd";
export const coreLightColorForegroundHighContrastDefault = "#000000";
export const coreLightColorBackgroundDefault = "#f5f6f7";
export const coreLightColorBackgroundInverse = "#1B1C1D";
export const coreLightColorBackgroundHighContrastDefault = "#ffffff";
export const coreLightColorPrimaryBackground = "#ebf2ff";
export const coreLightColorPrimaryForeground = "#0039af";
export const coreLightColorPrimaryMuted = "#2b69e1";
export const coreLightColorPrimaryLowContrast = "#8daeed";
export const coreLightColorPrimarySubtle = "#b6cbf3";
export const coreLightColorSecondaryBackground = "#efebff";
export const coreLightColorSecondaryForeground = "#29098d";
export const coreLightColorSecondaryMuted = "#5b3cc3";
export const coreLightColorSecondaryLowContrast = "#ac97f9";
export const coreLightColorSecondarySubtle = "#cbbefb";
export const coreDarkColorForegroundDefault = "#f5f6f7";
export const coreDarkColorForegroundInverse = "#303133";
export const coreDarkColorForegroundMutedDefault = "#cac6c7";
export const coreDarkColorForegroundLowContrastDefault = "#726f74";
export const coreDarkColorForegroundSubtleDefault = "#444549";
export const coreDarkColorForegroundHighContrastDefault = "#ffffff";
export const coreDarkColorBackgroundDefault = "#1B1C1D";
export const coreDarkColorBackgroundInverse = "#f5f6f7";
export const coreDarkColorBackgroundHighContrastDefault = "#000000";
export const coreDarkColorPrimaryDefault = "#d5e1f9";
export const coreDarkColorPrimaryAccent = "#2b69e1";
export const coreDarkColorSecondaryDefault = "#d5e1f9";
export const coreDarkColorSecondaryAccent = "#2b69e1";
export const systemElevation1 = "0 1px 1px 0 #0000001f";
export const systemElevation2 = "0 2px 3px -1px #00000014, 0 2px 2px -1px #0000000a";
export const systemElevation3 = "0 4px 6px -1px #0000000f, 0 2px 4px -1px #00000005";
export const systemElevation4 = "0 10px 15px -3px #0000000f, 0 4px 6px -2px #00000005";
export const systemElevation5 = "0 20px 25px -5px #0000000f, 0 10px 10px -5px #00000005";
export const systemElevation6 = "0 25px 50px -12px #00000033";
export const globalColorBaseNeutral0 = "#ffffff"; // #fff
export const globalColorBaseNeutral50 = "#f5f6f7"; // Background / #f5f6f7
export const globalColorBaseNeutral100 = "#ecebe9"; // #ecebe9
export const globalColorBaseNeutral200 = "#e0dedd"; // #e0dedd
export const globalColorBaseNeutral300 = "#cac6c7"; // Light / #cac6c7
export const globalColorBaseNeutral400 = "#B3ADAE";
export const globalColorBaseNeutral500 = "#726f74"; // Muted Text / #726f74
export const globalColorBaseNeutral600 = "#5a595e"; // #5a595e
export const globalColorBaseNeutral700 = "#444549"; // #444549
export const globalColorBaseNeutral800 = "#303133"; // Body Text / #303133
export const globalColorBaseNeutral900 = "#1B1C1D";
export const globalColorBaseNeutral1000 = "#000000"; // #000
export const globalColorBaseBlue50 = "#ebf2ff";
export const globalColorBaseBlue100 = "#d5e1f9"; // #b9d3fd
export const globalColorBaseBlue200 = "#b6cbf3"; // #8cb6fc
export const globalColorBaseBlue300 = "#8daeed"; // Link / #3b80f5
export const globalColorBaseBlue400 = "#5d8ce7"; // #0055eb
export const globalColorBaseBlue500 = "#2b69e1"; // #004bdb
export const globalColorBaseBlue600 = "#004bdb"; // #003fc4
export const globalColorBaseBlue700 = "#0039af"; // #0031a7
export const globalColorBaseBlue800 = "#002983"; // #002487
export const globalColorBaseBlue900 = "#001a57";
export const globalColorBasePurple50 = "#efebff";
export const globalColorBasePurple100 = "#e1d9fd";
export const globalColorBasePurple200 = "#cbbefb";
export const globalColorBasePurple300 = "#ac97f9";
export const globalColorBasePurple400 = "#8267de";
export const globalColorBasePurple500 = "#5b3cc3";
export const globalColorBasePurple600 = "#3d1ca8";
export const globalColorBasePurple700 = "#29098d";
export const globalColorBasePurple800 = "#1d0272";
export const globalColorBasePurple900 = "#160057";
export const globalFontFamilyDefault = "Plus Jakarta Sans";
export const globalFontFamilyDisplay = "Plus Jakarta Sans";
export const globalFontFamilyMono = "Space Mono";
export const globalFontFamilyCondensed = "Barlow Condensed";
export const globalFontSize0 = "8px";
export const globalFontSize1 = "10px";
export const globalFontSize2 = "12px";
export const globalFontSize3 = "14px";
export const globalFontSize4 = "18px";
export const globalFontSize5 = "22px";
export const globalFontSize6 = "28px";
export const globalFontSize7 = "32px";
export const globalFontSize8 = "36px";
export const globalFontSize9 = "40px";
export const globalFontSize10 = "48px";
export const globalFontSize11 = "60px";
export const globalFontSize12 = "80px";
export const globalFontSize13 = "96px";
export const globalFontSize14 = "120px";
export const globalFontWeightLight = "300";
export const globalFontWeightRegular = "400";
export const globalFontWeightMedium = "500";
export const globalFontWeightSemiBold = "600";
export const globalFontWeightBold = "700";
export const globalLineHeight100 = "1";
export const globalLineHeight125 = "1.25";
export const globalLineHeight150 = "1.5";
export const globalLetterSpacing0 = "-0.03em";
export const globalLetterSpacing1 = "-0.02em";
export const globalLetterSpacing2 = "-0.01em";
export const globalLetterSpacing3 = "0";
export const globalLetterSpacing4 = "0.01em";
export const globalLetterSpacing5 = "0.015em";
export const globalLetterSpacing6 = "0.02em";
export const globalLetterSpacing7 = "0.03em";
export const globalLetterSpacing8 = "0.06em";
export const globalLetterSpacing9 = "0.12em";
export const globalParagraphSpacing0 = 0;
export const globalParagraphSpacing1 = 12;
export const globalParagraphSpacing2 = 24;
export const globalRadiusDefault = "0.375rem";
export const globalRadiusPill = "100rem";
export const globalRadiusLarge = "0.75rem";
export const globalSpacing0 = "0";
export const globalSpacing1 = "6px";
export const globalSpacing2 = "12px";
export const globalSpacing3 = "18px";
export const globalSpacing4 = "24px";
export const globalSpacing5 = "36px";
export const globalSpacing6 = "48px";
export const globalSpacing7 = "96px";
export const globalTextCaseNone = "none";
export const globalTextCaseUppercase = "uppercase";
export const globalTextDecorationNone = "none";
export const globalTextDecorationUnderline = "underline";
export const globalTextLogoTypeSm = "600 18px/125% Space Mono";
export const globalTextLogoTypeLg = "600 60px/125% Space Mono";
export const globalOpacity0 = "0";
export const globalOpacity10 = "0.1";
export const globalOpacity20 = "0.2";
export const globalOpacity30 = "0.3";
export const globalOpacity40 = "0.4";
export const globalOpacity50 = "0.5";
export const globalOpacity60 = "0.6";
export const globalOpacity70 = "0.7";
export const globalOpacity80 = "0.8";
export const globalOpacity90 = "0.9";
export const globalOpacity100 = "1";