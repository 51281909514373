exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-how-i-built-a-new-color-system-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/how-i-built-a-new-color-system/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-how-i-built-a-new-color-system-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-should-designers-learn-to-code-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/should-designers-learn-to-code/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-should-designers-learn-to-code-index-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-content-projects-boulevard-developer-portal-index-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/content/projects/boulevard-developer-portal/index.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-content-projects-boulevard-developer-portal-index-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-content-projects-boulevard-ui-design-system-index-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/content/projects/boulevard-ui-design-system/index.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-content-projects-boulevard-ui-design-system-index-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-content-projects-first-american-ignite-design-system-index-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/content/projects/first-american-ignite-design-system/index.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-content-projects-first-american-ignite-design-system-index-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-content-projects-fullscreen-go-index-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/content/projects/fullscreen-go/index.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-content-projects-fullscreen-go-index-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-content-projects-pluto-tv-nitro-design-system-index-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/content/projects/pluto-tv-nitro-design-system/index.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-content-projects-pluto-tv-nitro-design-system-index-mdx" */),
  "component---src-templates-project-page-tsx-content-file-path-content-projects-taboola-newsroom-head-up-display-index-mdx": () => import("./../../../src/templates/project-page.tsx?__contentFilePath=/opt/build/repo/content/projects/taboola-newsroom-head-up-display/index.mdx" /* webpackChunkName: "component---src-templates-project-page-tsx-content-file-path-content-projects-taboola-newsroom-head-up-display-index-mdx" */)
}

